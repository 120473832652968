<template>
  <div>
    <Header />
    <div class="bg-light py-5">
      <div class="un-section-seller container">
        <div class="pt-4 d-flex justify-content-center align-items-center">
          <div class="my-2 text-center">
            <h2 class="fw-bold title-gradient">Airdrop List</h2>
            <p>All Information About Legit Crypto Airdrop</p>
          </div>
        </div>

        <div
          class="d-flex justify-content-center mt-5"
          v-for="(airdrop, index) in listAirdrop"
          :key="index"
        >
          <div class="bg-white card rounded-10" style="max-width: 650px">
            <div class="card-image">
              <img
                :src="
                  linkBanner +
                  'images/airdrop-banner/' +
                  airdrop.airdrop_banner_image
                "
                style="height: 285px; object-fit: cover !important"
                class="img-fluid w-100 rounded-10"
                alt=""
              />
              <a
                href=""
                class="
                  btn btn-xs-size
                  text-sm
                  shadow-lg
                  bg-success
                  position-absolute
                  start-0
                  m-3
                  text-decoration-none
                "
                >Live</a
              >
            </div>
            <div
              class="
                card-body
                z-1
                bottom-0
                bg-white
                w-100
                rounded-10
                position-absolute
              "
              style="
                background-color: rgba(0, 0, 0, 0.5) !important;
                backdrop-filter: blur(27px);
              "
            >
              <div
                class="
                  d-block d-md-flex d-lg-flex d-xl-flex
                  justify-content-between
                  w-100
                  align-items-center
                "
              >
                <div class="d-flex w-100 gap-2 align-items-center">
                  <img
                    :src="
                      linkBanner +
                      'images/airdrop-token/' +
                      airdrop.airdrop_image
                    "
                    width="50px"
                    height="50px"
                    alt=""
                  />
                  <div class="w-100 me-4">
                    <h5>
                      {{ airdrop.airdrop_title }}
                    </h5>
                    <div>
                      {{ airdrop.airdrop_subtitle }}
                    </div>
                  </div>
                </div>
                <div
                  class="
                    d-flex
                    justify-content-between
                    mt-3 mt-md-0 mt-lg-0 mt-xl-0
                    align-items-center
                  "
                >
                  <div class="text-nowrap me-4">
                    End: {{ airdrop.airdrop_end_date }}
                  </div>
                  <button
                    class="btn bg-success text-dark text-nowrap"
                    data-bs-toggle="modal"
                    :data-bs-target="'#exampleModal' + index"
                  >
                    Join Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            :id="'exampleModal' + index"
            tabindex="-1"
            :aria-labelledby="'exampleModalLabel' + index"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-sm modal-dialog-centered"
              style="max-width: 600px !important"
            >
              <div class="modal-content shadow-lg">
                <div class="modal-header bg-success border-bottom-0">
                  <h1
                    class="modal-title fs-5 text-dark"
                    :id="'exampleModalLabel' + index"
                  >
                    Airdrop Detail
                  </h1>
                  <button
                    type="button"
                    class="btn-close text-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex w-100 gap-2 align-items-center">
                    <img
                      :src="
                        linkBanner +
                        'images/airdrop-token/' +
                        airdrop.airdrop_image
                      "
                      width="50px"
                      height="50px"
                      alt=""
                    />
                    <div class="w-100 me-4">
                      <h6 class="fw-bold">
                        {{ airdrop.airdrop_title }}
                      </h6>
                      <div>
                        {{ airdrop.airdrop_subtitle }}
                      </div>
                    </div>
                  </div>
                  <div class="card bg-light mt-3">
                    <div class="card-body">
                      <div class="d-flex w-100 justify-content-between">
                        <div>
                          <div class="fw-medium mb-2">Amount</div>
                          <h6>{{ airdrop.airdrop_amount_title }}</h6>
                          <small class="text-secondary"
                            >{{ airdrop.airdrop_winner_count }} Winners</small
                          >
                        </div>
                        <div>
                          <div class="fw-medium mb-2">Registration Starts</div>
                          <h6>{{ airdrop.airdrop_start_date }}</h6>
                        </div>
                        <div>
                          <div class="fw-medium mb-2">Registration Ends</div>
                          <h6>{{ airdrop.airdrop_end_date }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3">
                    <h5>{{ airdrop.airdrop_short_description }}</h5>
                    <div>{{ airdrop.airdrop_description }}</div>
                  </div>

                  <div class="d-block mt-5">
                    <div class="text-center fw-bold">
                      To join this airdrop you'll need
                    </div>

                    <ol class="">
                      <li
                        v-for="(require, index) in airdrop.airdrop_requirement"
                        :key="index"
                        class="badge bg-success"
                      >
                        {{ require.type }}
                      </li>
                    </ol>
                    <br />
                  </div>
                  <div class="d-grid">
                    <a :href="airdrop.airdrop_link" class="btn bg-success"
                      >Join Now</a
                    >
                  </div>
                </div>
                <div class="modal-footer bg-light border-top-0">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import axios from "axios";

export default {
  name: "Airdrop",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      listAirdrop: [],
      linkBanner: process.env.VUE_APP_API,
    };
  },
  mounted() {
    this.getListAirdrop();
  },
  methods: {
    getListAirdrop() {
      axios.get(process.env.VUE_APP_API + "api/airdrop/list").then((res) => {
        this.listAirdrop = res.data.data;
      });
    },
  },
};
</script>