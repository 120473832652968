var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticClass:"bg-light py-5"},[_c('div',{staticClass:"un-section-seller container"},[_vm._m(0),_vm._l((_vm.listAirdrop),function(airdrop,index){return _c('div',{key:index,staticClass:"d-flex justify-content-center mt-5"},[_c('div',{staticClass:"bg-white card rounded-10",staticStyle:{"max-width":"650px"}},[_c('div',{staticClass:"card-image"},[_c('img',{staticClass:"img-fluid w-100 rounded-10",staticStyle:{"height":"285px","object-fit":"cover !important"},attrs:{"src":_vm.linkBanner +
                'images/airdrop-banner/' +
                airdrop.airdrop_banner_image,"alt":""}}),_c('a',{staticClass:"btn btn-xs-size text-sm shadow-lg bg-success position-absolute start-0 m-3 text-decoration-none",attrs:{"href":""}},[_vm._v("Live")])]),_c('div',{staticClass:"card-body z-1 bottom-0 bg-white w-100 rounded-10 position-absolute",staticStyle:{"background-color":"rgba(0, 0, 0, 0.5) !important","backdrop-filter":"blur(27px)"}},[_c('div',{staticClass:"d-block d-md-flex d-lg-flex d-xl-flex justify-content-between w-100 align-items-center"},[_c('div',{staticClass:"d-flex w-100 gap-2 align-items-center"},[_c('img',{attrs:{"src":_vm.linkBanner +
                    'images/airdrop-token/' +
                    airdrop.airdrop_image,"width":"50px","height":"50px","alt":""}}),_c('div',{staticClass:"w-100 me-4"},[_c('h5',[_vm._v(" "+_vm._s(airdrop.airdrop_title)+" ")]),_c('div',[_vm._v(" "+_vm._s(airdrop.airdrop_subtitle)+" ")])])]),_c('div',{staticClass:"d-flex justify-content-between mt-3 mt-md-0 mt-lg-0 mt-xl-0 align-items-center"},[_c('div',{staticClass:"text-nowrap me-4"},[_vm._v(" End: "+_vm._s(airdrop.airdrop_end_date)+" ")]),_c('button',{staticClass:"btn bg-success text-dark text-nowrap",attrs:{"data-bs-toggle":"modal","data-bs-target":'#exampleModal' + index}},[_vm._v(" Join Now ")])])])])]),_c('div',{staticClass:"modal fade",attrs:{"id":'exampleModal' + index,"tabindex":"-1","aria-labelledby":'exampleModalLabel' + index,"aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-sm modal-dialog-centered",staticStyle:{"max-width":"600px !important"}},[_c('div',{staticClass:"modal-content shadow-lg"},[_c('div',{staticClass:"modal-header bg-success border-bottom-0"},[_c('h1',{staticClass:"modal-title fs-5 text-dark",attrs:{"id":'exampleModalLabel' + index}},[_vm._v(" Airdrop Detail ")]),_c('button',{staticClass:"btn-close text-white",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}})]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"d-flex w-100 gap-2 align-items-center"},[_c('img',{attrs:{"src":_vm.linkBanner +
                      'images/airdrop-token/' +
                      airdrop.airdrop_image,"width":"50px","height":"50px","alt":""}}),_c('div',{staticClass:"w-100 me-4"},[_c('h6',{staticClass:"fw-bold"},[_vm._v(" "+_vm._s(airdrop.airdrop_title)+" ")]),_c('div',[_vm._v(" "+_vm._s(airdrop.airdrop_subtitle)+" ")])])]),_c('div',{staticClass:"card bg-light mt-3"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('div',[_c('div',{staticClass:"fw-medium mb-2"},[_vm._v("Amount")]),_c('h6',[_vm._v(_vm._s(airdrop.airdrop_amount_title))]),_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(airdrop.airdrop_winner_count)+" Winners")])]),_c('div',[_c('div',{staticClass:"fw-medium mb-2"},[_vm._v("Registration Starts")]),_c('h6',[_vm._v(_vm._s(airdrop.airdrop_start_date))])]),_c('div',[_c('div',{staticClass:"fw-medium mb-2"},[_vm._v("Registration Ends")]),_c('h6',[_vm._v(_vm._s(airdrop.airdrop_end_date))])])])])]),_c('div',{staticClass:"mt-3"},[_c('h5',[_vm._v(_vm._s(airdrop.airdrop_short_description))]),_c('div',[_vm._v(_vm._s(airdrop.airdrop_description))])]),_c('div',{staticClass:"d-block mt-5"},[_c('div',{staticClass:"text-center fw-bold"},[_vm._v(" To join this airdrop you'll need ")]),_c('ol',{},_vm._l((airdrop.airdrop_requirement),function(require,index){return _c('li',{key:index,staticClass:"badge bg-success"},[_vm._v(" "+_vm._s(require.type)+" ")])}),0),_c('br')]),_c('div',{staticClass:"d-grid"},[_c('a',{staticClass:"btn bg-success",attrs:{"href":airdrop.airdrop_link}},[_vm._v("Join Now")])])]),_vm._m(1,true)])])])])})],2)]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-4 d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"my-2 text-center"},[_c('h2',{staticClass:"fw-bold title-gradient"},[_vm._v("Airdrop List")]),_c('p',[_vm._v("All Information About Legit Crypto Airdrop")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-footer bg-light border-top-0"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-bs-dismiss":"modal"}},[_vm._v(" Close ")])])
}]

export { render, staticRenderFns }